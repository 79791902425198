@keyframes test {
  0% {
    transform: scale(2);
  }
  50% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes test2 {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(5);
  }
  100% {
    transform: scale(0.1);
  }
}

@keyframes test3 {
  0% {
    transform: scale(1) rotateY(0deg);
  }
  25% {
    transform: scale(5) rotateY(359deg);
  }
  50% {
    transform: scale(1) rotateY(0deg);
  }
  75% {
    transform: scale(0.1) rotateY(359deg);
  }
  100% {
    transform: scale(1) rotateY(0deg);
  }
}

.wallpaper {
  color: rgba(0, 255, 4, 0.261);
  position: absolute;
  top: 0;
  z-index: 0;
  user-select: none;
  animation: test2 30s ease-in-out;
  white-space: 20px;
  letter-spacing: 20px;
}
.zxc {
  animation: test3 1000s ease-in-out infinite;
  opacity: 0.3;
}
.char {
  user-select: none;
  animation: test 0.3s ease-in-out;
  color: rgb(0, 255, 4, 0.8);
  box-shadow: 0, 0, 1, 1, green;
  z-index: 3;
  position: relative;
}
body {
  background-color: black;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}
